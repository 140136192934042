"use client";

import React from "react";

import { SlideCarousel, SlideCarouselItem } from "@/components/Carousel/SlideCarousel";
import { CommonInterface, ImagePreviewInterface, useImageSrcSet } from "@/hook/ImageSrcSetHook";
import { StrapiComponentInterface } from "@/interfaces/Api/ApiInterface";
import { ImageInterface } from "@/interfaces/Carousel/CarouselInterface";

export interface SlideInterfaceItem {
  image?: ImageInterface;
  imageTablet?: ImageInterface;
  imageMobile?: ImageInterface;
  title?: string;
  subTitle?: string;
  text?: string;
  button?: string;
  buttonLink?: string;
  imageTitle?: string;
}

export interface ImageSliderInterface extends StrapiComponentInterface {
  slides?: SlideInterfaceItem[];
}

/**
 * Component Name to Render the Component based on dynamic zone response
 *
 * strapi component name:
 * __component: "slider.image-slider"
 */
export const IMAGE_SLIDER_COMPONENT_NAME = "slider.image-slider";

export const ImageSlider = ({ ...component }: ImageSliderInterface) => {
  const { preview, getImageConfiguration, getImage } = useImageSrcSet({ host: process.env.STRAPI_URL ?? "" });
  const slides = component.slides ?? [];

  if (!slides) {
    return null;
  }

  return (
    <SlideCarousel totalChildren={slides?.length ?? 0} buttonClassName={"w-full max-w-[1450px] mx-auto absolute h-[500px] top-0 -translate-x-1/2 left-1/2"}>
      {slides?.map((slide: SlideInterfaceItem & { index?: number }, index: number) => {
        const { image, imageTablet, imageMobile, imageTitle } = slide;
        const defaultImage = getImage(image) as string;

        const prepareCommon: CommonInterface = {
          title: imageTitle ?? "",
          alt: imageTitle ?? slide.image?.data?.attributes.name ?? "SliderImage preview",
        };

        const prepareImageConfiguration: ImagePreviewInterface = getImageConfiguration({
          desktop: defaultImage,
          tablet: getImage(imageTablet) ?? defaultImage,
          mobile: getImage(imageMobile) ?? defaultImage,
        });

        const { desktop, tablet, mobile } = preview(prepareCommon, prepareImageConfiguration);

        return (
          <SlideCarouselItem key={index}>
            <div className="max-w-[1500px] py-5 px-5 lg:px-8 mx-auto max-h-[475px]">
              <picture>
                <source media="(min-width: 1024px)" srcSet={desktop} />
                <source media="(min-width: 768px)" srcSet={tablet} />
                <img {...mobile} className={"w-full object-cover"} alt={prepareCommon.alt} />
              </picture>
            </div>
          </SlideCarouselItem>
        );
      })}
    </SlideCarousel>
  );
};
