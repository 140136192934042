"use client";

import Image from "next/image";

import { ImageInterface } from "@/interfaces/StrapiInterfaces";

interface ImageBlockInterface {
  image?: ImageInterface;
}

export const ImageBlock = ({ ...component }: ImageBlockInterface) => {
  if (!component.image) {
    return null;
  }

  const attributes = component.image.data?.attributes;

  return (
    <div className="mx-auto py-5">
      <div className="mb-10">
        <Image
          width={1000}
          height={1000}
          className="w-full"
          loader={(loader) => `${process.env.STRAPI_URL}${loader.src}`}
          src={attributes?.url as string}
          alt={attributes?.name ?? "Image Block"}
        />
      </div>
    </div>
  );
};
