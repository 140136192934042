"use client";

import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";

import { StrapiImage } from "@/data/strapi";

export interface InformationCardInterface {
  image: {
    data: StrapiImage;
  };
  title: string;
  description?: string;
  link?: string;
}

export const InformationCard = ({ ...component }: InformationCardInterface) => {
  const router = useRouter();

  const handleClick = () => {
    if (component?.link) {
      router.push(component.link);
    }
  };

  return (
    <div className="relative min-h-[25rem] cursor-pointer" onClick={() => handleClick()}>
      <p className="text-2xl font-medium text-kodi-blue mb-2">{component.title}</p>
      <Image
        width={100}
        height={100}
        className="w-full h-[15rem] object-cover"
        loader={(loader) => `${process.env.STRAPI_URL}${loader.src}`}
        src={component?.image?.data?.attributes?.url}
        alt={component?.image?.data?.attributes?.name ?? "Information Preview"}
      />
      {component?.description && (
        <p className="text-lg text-kodi-gray-600 mt-2">
          {component.description?.length > 50 ? component.description.substring(0, 300) : component.description}
        </p>
      )}

      {component?.link && (
        <div className="absolute bottom-0">
          <Link href={component?.link} className="text-kodi-blue underline text-lg cursor-pointer">
            Mehr erfahren
          </Link>
        </div>
      )}
    </div>
  );
};
