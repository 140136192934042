"use client";

import Image from "next/image";
import Link from "next/link";
import React from "react";

import { ContentManager, ProductSliderType } from "@/components/Slider/Product/components/ContentManager";
import { StrapiImage } from "@/data/strapi";

interface ProductSliderInterface {
  type?: ProductSliderType;
  category?: string;
  title?: string;
  medusa_key?: string;
  subTitle?: string;
  informationText?: string;
  thirdTitle?: string;
  button?: string;
  buttonLink?: string;
  textCardBackground?: {
    data: StrapiImage;
  };
  buttonColor?: string;
  buttonTextColor?: string;
  cardBackground?: string;
  productBackground?: string;
  textColor?: string;
}

export const ProductSlider = ({ ...component }: ProductSliderInterface) => {
  return (
    <div className="max-w-[1500px] mx-0 sm:mx-10 md:mx-auto py-5 px-5 lg:px-8 ">
      <div className="grid grid-cols-12 mx-auto" style={{ backgroundColor: `${component.productBackground}` }}>
        <div className="col-span-12 md:col-span-4 relative md:max-w-full md:max-h-full mx-auto my-auto">
          <div className="p-1 lg:p-5 text-center flex items-center h-full justify-center absolute inset-0 ">
            <Title {...component}>
              {component.button ? (
                <div className="absolute bottom-5 left-0 right-0">
                  <Link
                    href={component.buttonLink ?? `/sales/${component?.category?.toLowerCase()}` ?? "#!"}
                    className="py-2 px-5 shadow-lg rounded-lg font-bold text-md xl:text-2xl"
                    style={{ backgroundColor: `${component.buttonColor}`, color: `${component.buttonTextColor}` }}
                  >
                    {component.button}
                  </Link>
                </div>
              ) : (
                <></>
              )}
            </Title>
          </div>

          {component.textCardBackground && (
            <Image
              src={component.textCardBackground?.data?.attributes?.url as string}
              loader={(loader) => `${process.env.STRAPI_URL}${loader.src}`}
              width={1000}
              className="my-auto"
              height={1000}
              alt={component.textCardBackground?.data?.attributes.name ?? "Product Slider"}
            />
          )}
        </div>
        {component?.informationText && (
          <div className="col-span-12 block md:hidden p-2">
            <p className="font-medium text-center">{component?.informationText}</p>
          </div>
        )}
        <div className="col-span-12 md:col-span-8 flex flex-row items-center" style={{ backgroundColor: component.textColor }}>
          <ContentManager medusa_key={component?.medusa_key} type={component.type} categoryName={component.category} />
        </div>
      </div>
    </div>
  );
};

const Title = ({ children, ...component }: ProductSliderInterface & { children: React.ReactNode }) => {
  return (
    <div className="mb-3">
      <p className="text-xl sm:text-6xl md:text-5xl lg:text-7xl font-extralight text-kodi-blue p-0 break-all">{component?.title}</p>
      {component?.subTitle && (
        <p className="text-2xl sm:text-7xl md:text-6xl lg:text-8xl break-normal font-bold tracking-wider text-kodi-magenta p-0">{component?.subTitle}</p>
      )}
      {component.thirdTitle && (
        <p className="text-xl sm:text-6xl md:text-5xl lg:text-7xl break-normal font-bold tracking-wider text-kodi-magenta p-0">{component?.thirdTitle}</p>
      )}

      {children}
    </div>
  );
};
