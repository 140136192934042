import { Properties } from "csstype";
import React, { useEffect, useRef, useState } from "react";

import { handleCarouselIndex, SlideButton } from "@/components/Carousel/index";

export const SlideCarouselItem = ({ children, className = "", style = {} }: { children: React.ReactNode; className?: string; style?: Properties }) => {
  return (
    <div className={`relative duration-1000 flex-[0_0_100%] carousel-item ${className}`} style={style}>
      {children}
    </div>
  );
};

export const SlideCarousel = ({
  totalChildren,
  children,
  className,
  slideInterval = 5000,
  buttonClassName = "",
}: {
  totalChildren: number;
  children: React.ReactNode;
  slideInterval?: number;
  className?: string;
  buttonClassName?: string;
}) => {
  const [slide, setSlide] = useState<number>(0);
  const container = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlide((index: number) => handleCarouselIndex(true, index, totalChildren));
    }, slideInterval);

    return () => clearInterval(interval);
  }, [slide, totalChildren, slideInterval]);

  useEffect(() => {
    if (container.current) {
      const currentElement = container.current.childNodes.item(slide) as Element;

      if (!currentElement) {
        return;
      }

      if (!currentElement.classList.contains("hidden")) {
        currentElement.classList.add("hidden");
      }

      setTimeout(() => {
        if (currentElement.classList.contains("hidden")) {
          currentElement.classList.remove("hidden");
        }
      }, 100);
    }
  }, [slide]);

  const onClick = (next: boolean, currentIndex?: number) => {
    setSlide(handleCarouselIndex(next, currentIndex ?? slide, totalChildren));
  };

  const renderInputs = (labels: boolean) => {
    const inputs = [];

    for (let index = 0; index < totalChildren; index++) {
      if (labels) {
        inputs.push(
          <label
            key={index}
            htmlFor={`slideLabel${index}`}
            style={{
              backgroundColor: `${index === slide ? "#1e40af" : "transparent"}`,
            }}
            className="manual-btn"
          ></label>,
        );
      } else {
        inputs.push(
          <input
            key={index}
            type="radio"
            name="radio-btn"
            className="hidden"
            id={`slideLabel${index}`}
            onClick={() => {
              setSlide(index);
            }}
          />,
        );
      }
    }

    return inputs;
  };

  return (
    <div className={className}>
      <div className="relative w-full mb-[.75rem] top-[-2px] overflow-hidden">
        <div className="w-full overflow-hidden flex">
          {renderInputs(false)}

          <div className="w-full flex" style={{ marginLeft: `-${slide * 100}%` }} ref={container}>
            {children}
          </div>
        </div>

        <div className={buttonClassName}>
          <SlideButton onClick={() => onClick(false)} isNext={false} />
          <SlideButton onClick={() => onClick(true)} isNext={true} />
        </div>
      </div>
    </div>
  );
};
